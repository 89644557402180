/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types'
import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

export type outcomeType =
  | 'setSuccess'
  | 'verifySuccess'
  | 'setError'
  | 'verifyError'
type outcomeContent = Record<
  outcomeType,
  { heading: string; ingress: string; buttonText: string }
>

const OutcomeDialog = ({
  open,
  type,
}: {
  open: boolean
  type: outcomeType
}) => {
  const { serviceContent, serviceReturnUrl } = useEpiContent<Content>()
  const content: outcomeContent = {
    setSuccess: {
      heading: serviceContent.allreadyVerifiedDialogHeading,
      ingress: serviceContent.allreadyVerifiedDialogIngress,
      buttonText: serviceContent.allreadyVerifiedDialogButtonText,
    },
    verifySuccess: {
      heading: serviceContent.successVerifiedDialogHeading,
      ingress: serviceContent.successVerifiedDialogIngress,
      buttonText: serviceContent.successVerifiedDialogButtonText,
    },
    setError: {
      heading: serviceContent.errorSetDialogHeading,
      ingress: serviceContent.errorSetDialogIngress,
      buttonText: serviceContent.errorSetDialogButtonText,
    },
    verifyError: {
      heading: serviceContent.errorVerifiedDialogHeading,
      ingress: serviceContent.errorVerifiedDialogIngress,
      buttonText: serviceContent.errorVerifiedDialogButtonText,
    },
  }

  const handleClickButton = () => {
    if (type === 'setSuccess' || type === 'verifySuccess') {
      const userIsEnforcedMobileChange =
        history.state?.state?.isEnforcedToSetMobile
      const isEnforcedToSetContactInformation =
        history.state?.state?.isEnforcedToSetContactInformation

      const isEnforced =
        userIsEnforcedMobileChange ?? isEnforcedToSetContactInformation
      const redirectUrl = isEnforced
        ? serviceReturnUrl.returnUrlEnforcedMobile
        : serviceReturnUrl.returnUrl
      window.location.href = redirectUrl
      return
    }
    window.location.reload()
  }
  return (
    <Dialog
      data-testid={`${type}Dialog`}
      open={open}
      aria-labelledby={`${type}-heading`}
      aria-describedby={`${type}-ingress`}
    >
      <DialogTitle id={`${type}-heading`}>{content[type].heading}</DialogTitle>
      <DialogContent>
        <DialogContentText id={`${type}-heading`}>
          {content[type].ingress}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={handleClickButton}
          data-testid={`${type}DialogButton`}
        >
          {content[type].buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OutcomeDialog
