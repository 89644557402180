import React from 'react'
import { useCurrentKey, useEpiContent } from '@trr/app-shell-data'
import { ChangeInfo } from 'features'
import { Content, ServiceProps, Services } from 'types'
import { getServicesConfig } from 'servicesConfig'
import { Box } from '@mui/material'

export const App = () => {
  const currentKey = useCurrentKey<Services>()
  const {
    serviceContent: { setErrorFormat, setErrorNonEmpty },
  } = useEpiContent<Content>()

  const props: ServiceProps = getServicesConfig({
    setErrorNonEmpty,
    setErrorFormat,
  })[currentKey]

  return (
    <Box
      maxWidth={752}
      {...(window.location.pathname.includes('fullscreen') && {
        px: { xs: 2, sm: 3, md: 6 },
      })}
      mx={'auto'}
    >
      <ChangeInfo {...props} />
    </Box>
  )
}
