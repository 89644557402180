/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react/'
import { getIdToken } from '@trr/app-shell-data'
import { getConfig } from 'utils'

interface apiTransformedReturnType {
  statusCode: number | undefined
}

const { API_URL } = getConfig()

const trrFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken()}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })

const api = createApi({
  baseQuery: trrFetchBaseQuery(),
  endpoints: (builder) => ({
    putContactInfo: builder.query<
      apiTransformedReturnType,
      { endpoint: string; body: string }
    >({
      query: ({ endpoint, body }) => {
        return {
          url: endpoint,
          method: 'PUT',
          body: `"${body}"`,
          responseHandler: (response) => response.text(),
        }
      },
      transformResponse: (_response, headers) => {
        return { statusCode: headers?.response?.status }
      },
      transformErrorResponse: (response, _meta, arg) => ({
        ...response,
        originalPayload: arg.body,
      }),
    }),
  }),
})

export const { useLazyPutContactInfoQuery } = api

export default api
